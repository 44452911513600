export default new function () {
    let me = this;
    me.$ym = window.$ym;
    // 获取属性数据
    me.getMhData = function ({ prjCode }) {
        console.log(`${prjCode}.props`, me.$ym)
        return (me.$ym[`${prjCode}.props`]);
    }
    // 获取文章数据
    me.getMhArtical = async function ({ prjCode }) {
        return (me.$ym[`${prjCode}.artical`]);
    }
    // 获取文章详情
    me.getMhArticalDetail = async function ({ id, prjCode }) {
        const arr = await me.getMhArtical({ prjCode });
        console.log(arr,"00000000",prjCode)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].data) {
                let it = arr[i].data.find(x => x.id == id);
                if (it) {
                    return it;
                }
            }
        }
    }
}