import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: "/new",
    name: "new",
    component: () => import(/* webpackChunkName: "about" */ "@/views/New/index.vue"),
  },
  {
    path: "/new/detail",
    name: "NewDetail",
    component: () => import(/* webpackChunkName: "about" */ "@/views/New/detail.vue"),       
  },
  {
    path: "/business",
    name: "business",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Business"),       
  },
  {
    path: "/business/detail",
    name: "BusinessDetail",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Business/detail.vue"),       
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Aboutus"),       
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Contactus"),       
  },
  {
    path: "/joinus",
    name: "joinus",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Joinus"),       
  },
  {
    path: "/joinus/detail",
    name: "JoinusDetail",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Joinus/detail.vue"),       
  },
  { path: '/*', redirect: { name: 'home' }}
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.afterEach(()=>{
  document.documentElement.scrollTop = 0;
})
export default router;
