<template>
  <div id="app">
    <div class="h-p100 min-w1200" v-cloak>
      <div class="bg-main h-70 dis-flex a-i-center j-c-between plr-80 index-top">
        <div>
          <img width="64" height="34" src="@/assets/images/logo.png" />
        </div>
        <div class="dis-flex a-i-center">
          <ul class="dis-flex a-i-center index-top-nav">
            <router-link
              class="h-p100 dis-flex a-i-center"
              :to="{ name: item.content }"
              v-for="(item, index) in $t.homeNav||[]"
              :key="index"
            >
              <li v-if="item.ext1!='1'" class="plr-30 h-70 dis-flex a-i-center cp">{{ item.title }}</li>
            </router-link>
          </ul>
          <div
            @click="$app.chageLanguage()"
            class="cp bg-f c-main bra-half dis-flex a-i-center j-c-center index-top-lang"
          >{{ $app.curLan.next }}</div>
        </div>
      </div>
      <div class="pt-70">
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <router-view />
          </keep-alive>
        </transition>
      </div>
      <div class="bg-0 h-70 c-f fs-16 dis-flex flex-column j-c-center a-i-center index-bottom-txt">
        <div>
          <img width="47" height="25" src="@/assets/images/logo-bottom.jpg" />
          <span class="ml-10">{{$g("bottom.title")}}</span>
        </div>
        <div>
          <img width="13" src="@/assets/images/ba.png" alt />
          <a
            class="ml-6 index-bottom-txt"
            href="https://beian.mps.gov.cn/#/query/webSearch?code=31010102007956"
            rel="noreferrer"
            target="_blank"
          >沪公网安备31010102007956</a>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            class="ml-15 index-bottom-txt"
          >{{$g("bottom.content")}}</a>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      scroll: null,
    };
  },
  methods: {
    handleScroll() {
      this.scroll =
        document.documentElement && document.documentElement.scrollTop;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
.index-top {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
}
.index-top-nav a {
  color: rgba(255, 255, 255, 0.6);
}
.index-top-lang {
  width: 28px;
  height: 28px;
}
.index-bottom-txt {
  color: rgba(255, 255, 255, 0.6);
}

.index-top-nav .router-link-active {
  font-size: 16px;
  color: #fff;
}

/* fade-transform */
// .fade-transform-leave-active,
// .fade-transform-enter-active {
//   transition: all .0s;
// }

// .fade-transform-enter {
//   opacity: 0.1;
//   transform: translateX(-10px);
// }

// .fade-transform-leave-to {
//   opacity: 0.5;
//   transform: translateX(10px);
// }
</style>
